import React from 'react';
import { useLocation } from 'react-router-dom';
import { PrivacyPolicy } from '@b3w/pp-portal';
import { textAPI } from 'api';
import { getSafeString } from 'utils/getSafeString';
import Pages from 'pages';
import { Header } from 'components/Header/Header';
import { Menu } from 'components/Menu/Menu';
import { Approve, APPROVE_ROUTE } from '../pages/Approve/Approve';
import '@b3w/pp-portal/dist/portal-bundle.css';
import { Component } from './App.styled';
import ModalApp from "../components/ModalApp/ModalApp";


const App = () => {
    const { pathname } = useLocation();
    const { data, isLoading, isSuccess } = textAPI.useGetPolicyQuery();
    const content = getSafeString(data?.content);

    // For more information see component <Approve/>
    if (pathname === APPROVE_ROUTE) {
        return (
            <Component id="app">
                <Approve/>
            </Component>
        );
    }

    return (
        <Component id="app">
            <Header/>
            <Menu/>
            <Pages/>
            <ModalApp/>
            {/*// @ts-ignore*/}
            { !isLoading && isSuccess && <PrivacyPolicy dangerousText={content} adoption maxWidth={560} /> }
        </Component>
    )
};

export default App;
